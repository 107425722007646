import { useContext, useEffect, useMemo, useState } from 'react';
import { useRouter } from 'next/router';
import { store } from '@context/store';
import { isAValidEmail } from '@helpers/emailValidation';
import { getCookieValue, setCookie } from '@helpers/cookies';
import { sendEmailToIterable } from '@utils/services';
import toggleErrorPopup from '@helpers/toggleErrorPopup';
import { capitalize } from 'lodash';
import { useEvents } from '@events/EventsProvider';
import { EMAIL_CAPTURED } from '@constants/amplitudeEvents';
import { useUserLocation } from '@context/GeoIpDetectionContext';
import { GetDefaultSearchState } from '@context/SearchContext2025';
import { GenerateSearchUrl } from '@helpers/url';

export default function useMarketingModule(handleClick: () => void) {
  const { track } = useEvents();
  const { dispatch } = useContext(store);
  const userLocation = useUserLocation();

  const router = useRouter();

  const [currentStep, setCurrentStep] = useState(1);
  const [email, setEmail] = useState('');
  const [, setIsFormSubmitted] = useState(false);

  const ctaLink = useMemo(() => {
    if (userLocation) {
      const searchState = GetDefaultSearchState();
      searchState.city = userLocation.city_name;
      searchState.state_code = userLocation.state_code;
      searchState.country_code = userLocation.country_code;
      searchState.search_term = userLocation.name;

      // Old SRP: `/hotel-day-passes/${cityName}-${id}`
      return `/${GenerateSearchUrl(searchState)}`;
    }

    // Old SRP: '/hotel-day-passes/Los-Angeles-4'
    return '/s/us/ca/los-angeles/hotel-day-passes?date=any&search_term=Los+Angeles%2C+California&city_alias_id=4';
  }, [userLocation]);

  const handleCTAClick = () => router.push(ctaLink);

  const handleSubmit = async () => {
    if (!email || !isAValidEmail(email)) return;

    try {
      handleClick(); // track event

      track(EMAIL_CAPTURED, {
        email,
        source: router.pathname === '/' ? 'HomePageMarketingModule' : 'SRPMarketingModule',
      });

      await sendEmailToIterable(email);

      setCookie('mails_opt_in', 'true', 30);
      setIsFormSubmitted(true);
      setCurrentStep(2);
    } catch (error: any) {
      toggleErrorPopup(
        capitalize(error.response.data.errors[0].msg) || 'Email is not valid',
        dispatch,
      );
    }
  };

  useEffect(() => {
    if (getCookieValue('mails_opt_in') === 'true') {
      setCurrentStep(3);
    }
  }, []);

  return {
    currentStep,
    setEmail,
    handleSubmit,
    handleCTAClick,
    email,
  };
}
